import { Main } from "../Main";

export const PrivacyPolicyContent: React.FC = () => {
  return (
    <Main>
      <div className="bg-white/90 rounded-xl p-4 md:p-8">
        <header>
          <h1 className="text-4xl py-4">Privacy Policy</h1>
          <h2 className="text-xl pb-4 pt-8">
            This policy covers the Calories on Plate (CoP) application
          </h2>
          <p>
            This page is used to inform visitors about our policy regarding the
            use and disclosure of Personal Information if anyone decides to use
            our Service.
            <br />
            If you choose to use our Service, then you agree to our use of
            information in relation to this policy. The information we review is
            used for providing and improving the Service. We will not use or
            share your information with anyone except as described in this
            Privacy Policy.
          </p>
        </header>

        <h2 className="text-xl pb-4 pt-8">
          What personal information do we collect about you?
        </h2>
        <p>
          We do not currently collect any personal information. The app requires
          the user's gender, age, height, weight, and activity level for
          personal health insights and goal setting. This information is only
          used by the application and is not transmitted or stored on the
          server.
        </p>

        <h2 className="text-xl pb-4 pt-8">
          What information do we collect about you?
        </h2>

        <p>
          For a better experience, we review the general information provided by
          the service that hosts the application. This information includes:
          <br />
          Number of CoP application installations and active users
          <br />
          User's device OS and its version
          <br />
          User's country
          <br />
          User's device language setting
          <br />
          CoP application version
        </p>
        <br />

        <h2 className="text-xl pb-4 pt-8">
          Do we share your information with anyone else?
        </h2>
        <p>
          If required by law, we may disclose your information to the police,
          regulatory bodies, or legal advisers in connection with any alleged
          criminal offense, legal proceedings, or a suspected breach of the
          Terms of Use.
          <br />
          Your information rights are under the General Data Protection
          Regulations (GDPR).
        </p>

        <h2 className="text-xl pb-4 pt-8">Service Providers</h2>
        <p>
          AdMob is one of the world's largest mobile advertising platforms
          providing banner and rewarded ads for mobile apps. Please refer to
          their privacy policy.
        </p>

        <h2 className="text-xl pb-4 pt-8" id="externalAPI">
          Health Information
        </h2>
        <p>
          CoP performs health calculations such as body mass index (BMI) and
          active metabolic rate (AMR) to provide you with personalized health
          recommendations. However, it is important to note that these
          calculations are based on averages and may not be accurate for
          everyone. In particular, people with large muscle mass, such as
          athletes or people with heavy bones, may get results that do not
          accurately reflect their actual health status. Therefore, we recommend
          that you consult with a healthcare professional if you have any doubts
          about the accuracy of these calculations for your particular
          situation.
        </p>

        <h2 className="text-xl pb-4 pt-8" id="externalAPI">
          Product Information
        </h2>
        <p>
          CoP products database contains only the common unbranded products. The
          nutritional information on products is based on different variations
          and origins for the same types of products. It cannot be 100% accurate
          for each product and should be used as a reference.
        </p>
        <br />
        <p>
          CoP uses the Open Food Facts crowdsourced API which is a great source
          of information for the common and branded products. Products added by
          community users, which means that some of the information may be
          incomplete, outdated or incorrect. Although the API provides useful
          reference, it is the user's responsibility to verify the accuracy of
          the information before use. Refer to the Open Food Facts privacy
          policy for more information, or visit the official website
          https://openfoodfacts.org to contribute to the API.
        </p>

        <h2 className="text-xl pb-4 pt-8">Changes to this Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. This policy is effective as of July 2021.
        </p>

        <h2 className="text-xl pb-4 pt-8">Contact us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at apps@platechampions.com
        </p>

        <h2 className="text-xl pb-4 pt-8">Rights & Liability</h2>
        <p>
          It is absolutely essential that you understand that CoP is intended
          only as a reference guide and as an aid to your daily calorie
          management. Even though product information tends to be as accurate as
          possible, high accuracy cannot be guaranteed due to variation and
          origins. The app is not intended to be used as a medical device, and
          if the information in the app is used to calculate insulin doses or
          for any other medical purpose, you agree that this is done at your own
          risk and you take full responsibility for the outcome. The developers
          are not liable to you under any theory of liability or damages in
          connection with your use of Calories on Plate (CoP) application.
        </p>
      </div>
    </Main>
  );
};
