import { Footer } from "../../components/carbs-on-plate/Footer";
import { Navbar } from "../../components/Navbar";
import { UserGuideContent } from "../../components/carbs-on-plate/UserGuideContent";

export const UserGuidePage: React.FC = () => {
  return (
    <>
      <Navbar />
      <UserGuideContent />
      <Footer />
    </>
  );
};
