import { Main } from "../components/Main";
import { Navbar } from "../components/Navbar";
import { Link } from "react-router-dom";
import carbsOnPlate from "./../assets/icon-carbs.png";
import calorisOnPlate from "./../assets/icon-calories.png";

export const HomePage: React.FC = () => {
  return (
    <>
      <Navbar />
      <Main>
        <header className=" p-4 md:p-6">
          <h2 className="text-2xl lg:text-3xl leading-tight text-white drop-shadow-[-5px_5px_10px_rgba(12,17,109,1)]">
            Mobile applications that support a 
          </h2>
          <h2 className="text-4xl lg:text-5xl leading-tight text-secondary drop-shadow-[-5px_5px_10px_rgba(12,17,109,1)]">Healthy Lifestyle</h2>
        </header>

        <div className="flex flex-col lg:flex-row my-12">
          <div className="bg-gradient-to-b from-slate-900 via-primary-carbs to-slate-800 p-8 rounded-xl m-2 md:m-4 shadow-lg">
            <img
              className="m-auto w-[125px] my-6"
              src={carbsOnPlate}
              alt="Carbs on Plate application logo"
            />
            <div className="text-4xl text-white text-center m-4">
              Carbs on Plate
            </div>
            <div className="text-white md:text-lg p-2 md:p-4">
              Carbs on Plate (CoP) is a simple carb management app designed to
              help diabetics with the critical and sometimes tedious daily tasks
              of counting carbs. It provides a great overview of carbs consumed
              over time, sugar amounts, and other nutrient information that can
              help you eat a balanced diet.
            </div>
            <div className="flex justify-center my-2">
              <Link
                to="/carbs-on-plate"
                className="py-2 px-4 mt-2 bg-slate-900 rounded-full text-white"
              >
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                    />
                  </svg>
                  Learn More
                </div>
              </Link>
            </div>
          </div>

          <div className="bg-gradient-to-b from-slate-900 via-primary-calories to-slate-800 p-8 rounded-xl m-2 md:m-4 shadow-lg">
            <img
              className="m-auto w-[125px] my-6"
              src={calorisOnPlate}
              alt="Carbs on Plate application logo"
            />
            <div className="text-4xl text-white text-center m-4">
              Calories on Plate
            </div>
            <div className="text-white md:text-lg p-2 md:p-4">
              Calories on Plate (CoP) is a simple Android calorie management
              application designed to help people maintain a healthy weight
              through calorie counting and healthy eating. It provides
              personalized health information, allows you to set a target
              weight, predicts when your target can be reached, and gives you a
              great overview of your progress.
            </div>
            <div className="flex justify-center my-2">
              <Link
                to="/calories-on-plate"
                className="py-2 px-4 mt-2 bg-slate-900 rounded-full text-white"
              >
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                    />
                  </svg>
                  Learn More
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};
