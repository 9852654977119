import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { UserGuidePage } from "./pages/carbs-on-plate/UserGuidePage";
import { PrivacyPolicyPage as PPCarbsOnPlate } from "./pages/carbs-on-plate/PrivacyPolicyPage";
import { PrivacyPolicyPage as PPCaloriesOnPlate } from "./pages/calories-on-plate/PrivacyPolicyPage";
import { PrivacyPolicyContent as PPCCarbsOnPlate } from "./components/carbs-on-plate/PrivacyPolicyContent";
import { PrivacyPolicyContent as PPCCaloriesOnPlate } from "./components/calories-on-plate/PrivacyPolicyContent";
import { UserGuideContent } from "./components/carbs-on-plate/UserGuideContent";
import { CarbsOnPlatePage } from "./pages/carbs-on-plate/CarbsOnPlatePage";
import splat from "./assets/splat.png";
import { CaloriesOnPlatePage } from "./pages/calories-on-plate/CaloriesOnPlatePage";

function App() {
  return (
    <Router>
      {/* <div className="flex flex-col min-h-screen bg-gradient-to-b from-primary via-tertiary to-gray-800"> */}
      <div className="relative flex flex-col min-h-screen bg-slate-900">
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/carbs-on-plate" element={<CarbsOnPlatePage />} />
          <Route path="/carbs-on-plate/privacy-policy" element={<PPCarbsOnPlate />} />
          <Route path="/carbs-on-plate/privacy-policy-content" element={<PPCCarbsOnPlate />} />
          <Route path="/carbs-on-plate/user-guide" element={<UserGuidePage />} />
          <Route path="/carbs-on-plate/user-guide-content" element={<UserGuideContent />} />

          <Route path="/calories-on-plate" element={<CaloriesOnPlatePage />} />
          <Route path="/calories-on-plate/privacy-policy" element={<PPCaloriesOnPlate />} />
          <Route path="/calories-on-plate/privacy-policy-content" element={<PPCCaloriesOnPlate />} />
        </Routes>
        <div className="absolute top-0 w-full h-full opacity-70 z-10">
          {/* <img
            className="object-cover h-full m-auto"
            src={fitness}
            alt="smartphone background"
          /> */}
          <img
            className="absolute top-0 object-cover h-full m-auto opacity-30 blur"
            src={splat}
            alt="smartphone background"
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
