import { Main } from "../Main";

export const PrivacyPolicyContent: React.FC = () => {
  return (
    <Main>
      <div className="bg-white/90 rounded-xl p-4 md:p-8">
        <header>
          <h1 className="text-4xl py-4">Privacy Policy</h1>
          <h2 className="text-xl pb-4 pt-8">
            This policy covers the Carbs on Plate (CoP) application
          </h2>
          <p>
            This page is used to inform visitors about our policy regarding the
            use and disclosure of Personal Information if anyone decides to use
            our Service.
            <br />
            If you choose to use our Service, then you agree to our use of
            information in relation to this policy. The information we review is
            used for providing and improving the Service. We will not use or
            share your information with anyone except as described in this
            Privacy Policy.
          </p>
        </header>

        <h2 className="text-xl pb-4 pt-8">
          What personal information do we collect about you?
        </h2>
        <p>
          We collect user emails to provide secure account access and
          promotional communications if users wish to subscribe.
        </p>
        <p>
          We collect usernames to provide a more personalised in-app and email
          experience if users choose to provide them.
        </p>

        <h2 className="text-xl pb-4 pt-8">
          What information do we collect about you?
        </h2>

        <p>
          For a better experience, we review the general information provided by
          the service that hosts the application. This information includes:
          <br />
          Number of CoP application installations and active users
          <br />
          User's device OS and its version
          <br />
          User's country
          <br />
          User's device language setting
          <br />
          CoP application version
        </p>
        <br />
        <p>
          We may use the aggregated data to improve the information in the
          application and to make changes to existing features, as well as to
          develop new features. This information may also be used to provide you
          with helpful tips, reminders, and motivational messages to help you
          achieve your goals. Push notifications may be sent to provide this
          information if you give us permission to do so (this is controlled in
          your device settings). The information does not personally identify
          users.
        </p>

        <h2 className="text-xl pb-4 pt-8">
          Do we share your information with anyone else?
        </h2>
        <p>
          If required by law, we may disclose your information to the police,
          regulatory bodies, or legal advisers in connection with any alleged
          criminal offense, legal proceedings, or a suspected breach of the
          Terms of Use.
          <br />
          Your information rights are under the General Data Protection
          Regulations (GDPR).
        </p>

        <h2 className="text-xl pb-4 pt-8">Service Providers</h2>
        <p>
          We employ third-party service providers to provide our services on our
          behalf. Service providers have access to user information necessary to
          provide their services. However, they are required not to disclose or
          use the information for any other purpose.
        </p>
        <br />
        <p>
          Firebase Service provider is BaaS platform that provides secure user
          account management and data storage. Please refer to their privacy
          policy.
        </p>
        <br />
        <p>
          AdMob is one of the world's largest mobile advertising platforms
          providing banner ads for mobile apps. Please refer to their privacy
          policy.
        </p>

        <h2 className="text-xl pb-4 pt-8" id="externalAPI">
          Product Information
        </h2>
        <p>
          CoP products database contains only the common unbranded products. The
          nutritional information on products is based on different variations
          and origins for the same types of products. It cannot be 100% accurate
          for each product and should be used as a reference.
        </p>
        <br />
        <p>
          CoP uses the Open Food Facts crowdsourced API which is a great source
          of information for the common and branded products. Products added by
          community users, which means that some of the information may be
          incomplete, outdated or incorrect. Although the API provides useful
          reference, it is the user's responsibility to verify the accuracy of
          the information before use. Refer to the Open Food Facts privacy policy for more
          information, or visit the official website https://openfoodfacts.org to contribute to the API.
        </p>

        <h2 className="text-xl pb-4 pt-8">Changes to this Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. This policy is effective as of July 2021.
        </p>

        <h2 className="text-xl pb-4 pt-8">Contact us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at carbsonplate@gmail.com
        </p>

        <h2 className="text-xl pb-4 pt-8">Rights & Liability</h2>
        <p>
          It is absolutely essential that you understand that CoP is intended
          only as a reference guide and as an aid to your daily carbohydrate
          management. Even though product information tends to be as accurate as
          possible, high accuracy cannot be guaranteed due to variation and
          origins. The app is not intended to be used as a medical device, and
          if the information in the app is used to calculate insulin doses or
          for any other medical purpose, you agree that this is done at your own
          risk and you take full responsibility for the outcome. The developers
          are not liable to you under any theory of liability or damages in
          connection with your use of Carbs on Plate (CoP) application.
        </p>
      </div>
    </Main>
  );
};
