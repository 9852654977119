import { Footer } from "../../components/calories-on-plate/Footer";
import { Navbar } from "../../components/Navbar";
import { PrivacyPolicyContent } from "../../components/calories-on-plate/PrivacyPolicyContent";

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};
