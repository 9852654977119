import { Main } from "../Main";

export const UserGuideContent: React.FC = () => {
  const data = [
    {
      title: "Plate (Meal log)",
      items: [
        {
          name: "Add new Plate",
          content:
            "A new plate can be added in a couple of ways. 1. By tapping on Add plate button displayed on days without any logs. 2. By tapping on Add to a new plate from a product calculator.",
        },
        {
          name: "Edit Plate name",
          content:
            "When a plate is created the name is autogenerated depending on the time of the day (ex. Breakfast, Lunch, etc.) and can be updated by tapping the name under the plate on the selected day view.",
        },
        {
          name: "Copy Plate",
          content:
            'Most people like a routine and having similar meals from time to time. Previous plate logs can be copied to another day by tapping the kebab menu (3 vertical dots) on a plate view, selecting "Copy plate", and picking a date the plate needs to be copied to.',
        },
        {
          name: "Remove Plate",
          content:
            'The plate and its content can be removed by tapping the kebab menu (3 vertical dots) on a plate view, and selecting "Delete".',
        },
      ],
    },
    {
      title: "Add product to plate",
      items: [
        {
          name: "From Plate",
          content:
            "A product can be added to a plate from its view in a couple of ways. 1. By using a search to add the existing product. 2. By adding a new or a one-time product using the calculator.",
        },
        {
          name: "From Home page",
          content:
            "A product can be added to a plate from the home page in a couple of ways. 1. By using a search to add the existing product. 2. By adding a new or a one-time product using the calculator. The user has the choice to pick one of today's plates to include the product in.",
        },
        {
          name: "From Products library",
          content:
            "A product can be added to a plate from the products library by tapping on a product and selecting the calculator. The user has the choice to pick one of today's plates to include the product in.",
        },
        {
          name: "Add One-time product",
          content:
            "A one-time product is a product that is unlikely to be consumed by a user again and is not worth being added to the user's library. Users can add it by using the calculator available from multiple locations in the application.",
        },
      ],
    },
    {
      title: "Edit / Remove plate product",
      items: [
        {
          name: "Edit product weight/quantity",
          content:
            'The weight or quantity of a product on a plate can be changed by tapping on a product and selecting a "Change portion" button.',
        },
        {
          name: "Edit One-time product",
          content:
            'A One-time product can also be updated by tapping on a product and selecting the "Edit" button where the user can change the product\'s name, units, and nutritions, and add/remove categories. The weight or quantity of a product can also be changed by tapping on a product and selecting a "Change portion" button.',
        },
        {
          name: "Remove product",
          content:
            'A product can be removed from a plate by tapping on it and selecting "Remove".',
        },
      ],
    },
    {
      title: "Add product to library",
      items: [
        {
          name: "One-time product",
          content:
            'A One-time product added to a plate can be saved to the user\'s library by tapping it and selecting the "Add to library" button. After the product is saved it can be found in search and added to other plates. Also, users can view, update or remove it from a library page.',
        },
        {
          name: "From library",
          content:
            'Products can also be added from the Products library page by tapping the "+" button in the bottom right corner and filling in the product information.',
        },
        {
          name: "By copying a product",
          content:
            'Users can also create their own variations of products by tapping on any application product and selecting "Copy". This will create a duplicate that can be edited by selecting the "Edit" button, and updating information like name, units, nutrition, and categories.',
        },
      ],
    },
    {
      title: "Edit / Remove product in library",
      items: [
        {
          name: "Edit user product",
          content:
            'Any product created by a user can be edited from the library page by tapping on a product and selecting the "Edit" button. Users can use the search to find a product or use a filter in the top right corner to display only the manually added products, so it\'s easier to find the desired product.',
        },
        {
          name: "Remove user product",
          content:
            'Any product created by a user can also be removed from the library page by tapping on a product and selecting the "Remove" button. The removed product will no longer be available for new plates but will remain on existing ones.',
        },
      ],
    },
    {
      title: "Insights",
      items: [
        {
          name: "Of which Sugars %",
          content:
            "The Insights page can display a percentage of sugar out of total carbohydrates logged on plates in the last 7 (a week), 30 (a month), or 90 days (3 months). Some dietitians are recommending not exceeding a certain amount, and this insight provides greater visibility on sugar consumption.",
        },
        {
          name: "Balanced diet",
          content:
            "The Insights page displays the balanced diet target (the green shape) and the user's high-level balanced diet representation based on logged products in the last 7 (a week), 30 (a month), or 90 days (3 months). This chart shows how close or far the user is from a healthy and balanced diet.",
        },
      ],
    },
  ];

  const gotoButton = async (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Main>
      <div className="overflow-scrollable">
        <header className="p-4">
          <h1 className="text-5xl pb-4 text-light">Information</h1>
          <h2 className="text-secondary/90 text-xl md:text-2xl lg:text-3xl leading-tight">
            What CoP (Carbs on Plate) application can do for me?
          </h2>
        </header>
        <section className="text-slate-300 leading-tight p-4 rounded-2xl shadow-md shadow-primary/30 overflow-scrollable">
          <h3 className="text-3xl uppercase px-4 py-2">Q/A</h3>
          <details>
            <summary className="pt-4 pb-2 text-lg">
              How does the carbohydrates counting work?
            </summary>
            <p className="text-slate-400">
              CoP can help you quickly find thousands of food products by name
              or using a barcode scanner and add them to your plate (food
              journal). The plate will list all added foods and list the total
              carbs, including sugar information. The user can freely enter the
              own weight of the product and adjust it if necessary.
            </p>
          </details>
          <details>
            <summary className="pt-4 pb-2 text-lg">
              Does the app track calories and other nutrients?
            </summary>
            <p className="text-slate-400">
              Yes, CoP application also counts and displays calories, fat,
              protein and fiber for each plate. However, it was designed to
              solve the problem of counting carbohydrates for people with
              diabetes, so calories and other nutrients can be turned off in the
              settings. The Insights page also shows the total amount of
              nutrients consumed during the displayed time periods.
            </p>
          </details>
          <details>
            <summary className="pt-4 pb-2 text-lg">
              What makes CoP app special?
            </summary>
            <p className="text-slate-400">
              The CoP application makes it easy to find and reuse repeat plate
              logs. Allows users to add new products, copy and modify existing
              ones, and manage foods in their personal My Products library. Many
              foods also have supporting information about the product's usual
              serving sizes, so users can make a more accurate guess when scales
              are not available. Finally, the application provides useful
              insights based on user-entered information so that users can see
              patterns in their eating habits.
            </p>
          </details>
          <details>
            <summary className="pt-4 pb-2 text-lg">
              Where does product information come from?
            </summary>
            <p className="text-slate-400">
              CoP maintains a quality database of common unbranded products.
              Information about each product is taken from products of the same
              type, but of different origins and different variations, which
              ensures a higher percentage of accuracy. In addition, CoP uses the
              Open Food Facts API to find branded products. This is an open
              source product database which is a great source of information,
              even some of the information entered by users may be inaccurate or
              slightly out of date. We always recommend that you double check
              the information on the product packaging.
            </p>
          </details>
        </section>
        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-8">
            {data.map((section, i) => (
              <div
                key={i}
                className="text-white rounded-xl shadow-lg bg-gradient-to-b from-primary to-slate-700 py-4 px-6"
              >
                <h3 className="font-bold text-lg">{section.title}</h3>
                <ol className="py-4 text-gray-200">
                  {section.items.map((item, i) => (
                    <li key={i}>
                      <div
                        onClick={() =>
                          gotoButton(
                            item.name.toLowerCase().split(" ").join("_")
                          )
                        }
                        className="hover:text-secondary"
                      >
                        {item.name}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>
        </section>
        <section className="bg-white/90 rounded-xl shadow-xl py-2 px-8 my-4  overflow-scrollable">
          {data.map((section, i) => (
            <div key={i} className="my-4">
              <h2 className="text-xl text-tertiary font-bold uppercase py-4">
                {section.title}
              </h2>
              {section.items.map((item, i) => (
                <div
                  key={i}
                  className="mt-4 mb-8"
                  id={item.name.toLowerCase().split(" ").join("_")}
                >
                  <h3 className="uppercase font-bold text-primary">
                    {item.name}
                  </h3>
                  <p className="text-gray-900">{item.content}</p>
                </div>
              ))}
            </div>
          ))}
        </section>
      </div>
    </Main>
  );
};
