import { Link } from "react-router-dom";
import logo from "./../assets/icon-fun.png";

export const Navbar: React.FC = () => {
  return (
    <nav className="flex w-full h-[90px] justify-between items-center p-4 z-20 bg-gradient-to-b from-black/80 to-transparent">
      <Link to="/" className="flex items-center">
        <img src={logo} width="65" height="65" alt="logo" className="shadow-lg shadow-black rounded-full" />
        <div className="ml-4 text-white">by</div>
        <h1 className="text-3xl md:text-4xl ml-2 text-white">
          Plate Champions
        </h1>
      </Link>
    </nav>
  );
};
